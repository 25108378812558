import 'bootstrap'
import 'stylesheets/website.scss'
import { initChat } from './src/chat'

require.context('./images', true)

const initSticky = function() {
  window.onscroll = function() {
    const udHeader = document.querySelector('.ud-header')
    const sticky = udHeader.offsetTop

    if (window.pageYOffset > sticky) {
      udHeader.classList.add('sticky')
    } else {
      udHeader.classList.remove('sticky')
    }
  }
}

const initNavbar = function() {
  const navbarToggler = document.querySelector('.navbar-toggler')
  const navbarCollapse = document.querySelector('.navbar-collapse')

  document.querySelectorAll('.ud-menu-scroll').forEach((e) =>
    e.addEventListener('click', () => {
      navbarToggler.classList.remove('active')
      navbarCollapse.classList.remove('show')
    })
  )
  navbarToggler.addEventListener('click', function() {
    navbarToggler.classList.toggle('active')
    navbarCollapse.classList.toggle('show')
  })
}

const initSubmenu = function() {
  const submenuButton = document.querySelectorAll('.nav-item-has-children')
  submenuButton.forEach((elem) => {
    elem.querySelector('a').addEventListener('click', () => {
      elem.querySelector('.ud-submenu').classList.toggle('show')
    })
  })
}

initSubmenu()
initNavbar()
initSticky()
initChat()
