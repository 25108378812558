export const initChat = function() {
  const tokenElement = document.getElementById('cw-token')
  if (tokenElement && tokenElement.dataset.token) {
    (function(d, t) {
      const BASE_URL = 'https://chat.robotjara.cz'
      const g = d.createElement(t); const s = d.getElementsByTagName(t)[0]
      g.src = BASE_URL + '/packs/js/sdk.js'
      g.defer = true
      g.async = true
      s.parentNode.insertBefore(g, s)
      g.onload = function() {
        window.chatwootSDK.run({
          websiteToken: tokenElement.dataset.token,
          baseUrl: BASE_URL
        })
      }
    })(document, 'script')
  }
}
